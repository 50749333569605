@import "./style/buttons";

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    font-size: 1em;
    @media(min-width: 30em) {
        font-size: 1.25em;
    }
    @media(min-width: 80em) {
        font-size: 1.625em;
    }
    font-family: Helvetica, sans-serif;

    &.home {
        background: linear-gradient(to bottom, #fff, #B9ACCA, #fff);
        #app {
            text-align: center;
            margin-bottom: 20vh;
        }

        @media(min-width:60em) {
            #app {
                font-size: .825em;
                transform: translateY(-3em);
            }
        }

        .header {
            margin-top: 2em;
        }
    }

    &.app {
        background: linear-gradient(to bottom, #fff, #f0f0f0, #fff);

        .header {
            font-size: .825em;
            margin-bottom: 1.25em;

            .motto {
                display: none;
            }
        }

        .footer {
            display: none;
        }

        .landing {
            display: none;
        }
    }
}

.header {
    a {
        text-decoration: none;
    }
}

.check-the-demo {
    font-size: .625em;
}

.motto {
    font-size: 1.25em;
    margin-bottom: 2em;
    @media(min-width: 60em) {
        margin-bottom: 4em;
    }

    @media(min-width: 80em) {
        margin-bottom: 6em;
    }
}

ul {
    padding: 1em 1em 0 1em;
    li {
        margin-bottom: 1em;
    }
}

.radio-control {
    display: flex;
    justify-content: center;
    margin: 0 0 .625em 0;
}

.form-control {
    display: inline-block;
}

label {
    display: inline-block;
    padding: .625em;

    &:hover {
        cursor: pointer;
    }
}

input[type="radio"] {
    transform: translateX(-9999vh);
}

input[type="radio"]:checked + label {
    border-bottom: 2px solid #ccc;
}
input[type="radio"]:focus + label {
    border-bottom: 2px solid #ccc;
    outline: groove;
}

select {
    display: block;
    font-size: 0.75em;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg+xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22+width%3D%22292.4%22+height%3D%22292.4%22%3E%3Cpath+d%3D%22M287+69.4a17.6+17.6+0+0+0-13-5.4H18.4c-5+0-9.3+1.8-12.9+5.4A17.6+17.6+0+0+0+0+82.2c0+5+1.8+9.3+5.4+12.9l128+127.9c3.6+3.6+7.8+5.4+12.8+5.4s9.2-1.8+12.8-5.4L287+95c3.5-3.5+5.4-7.8+5.4-12.8+0-5-1.9-9.2-5.5-12.8z%22%2F%3E%3Cstyle%3Epath+%7B+fill%3A+currentColor%3B+%7D%3C%2Fstyle%3E%3C%2Fsvg%3E'),
                      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    height: 3em;
    @media(min-width: 40em) {
        height: 2.5em;
    }
}
select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}
select option {
    font-weight:normal;
}

input[type="date"] {
    -webkit-align-items: center;
    display: -webkit-inline-flex;
    font-family: monospace;
    overflow: hidden;
    padding: 0;
    -webkit-padding-start: 1px;
}

input::-webkit-datetime-edit {
    -webkit-flex: 1;
    -webkit-user-modify: read-only !important;
    display: inline-block;
    min-width: 0;
    overflow: hidden;
}
input::-webkit-datetime-edit-fields-wrapper {
    -webkit-user-modify: read-only !important;
    display: inline-block;
    padding: 1px 0;
    white-space: pre;
}

/* If you update padding, border, or margin in the following ruleset, update
   DateTimeFieldElement::maximumWidth too. */
input::-webkit-datetime-edit-ampm-field,
input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-hour-field,
input::-webkit-datetime-edit-millisecond-field,
input::-webkit-datetime-edit-minute-field,
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-second-field,
input::-webkit-datetime-edit-week-field,
input::-webkit-datetime-edit-year-field {
    -webkit-user-modify: read-only !important;
    border: none;
    display: inline;
    font: inherit !important;
    padding: 1px;
}

/* Remove focus ring from fields and use highlight color */
input::-webkit-datetime-edit-ampm-field:focus,
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-hour-field:focus,
input::-webkit-datetime-edit-millisecond-field:focus,
input::-webkit-datetime-edit-minute-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-second-field:focus,
input::-webkit-datetime-edit-week-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: highlight;
    color: highlighttext;
    outline: none;
}

input::-webkit-datetime-edit-year-field[disabled],
input::-webkit-datetime-edit-month-field[disabled],
input::-webkit-datetime-edit-week-field[disabled],
input::-webkit-datetime-edit-day-field[disabled],
input::-webkit-datetime-edit-ampm-field[disabled],
input::-webkit-datetime-edit-hour-field[disabled],
input::-webkit-datetime-edit-millisecond-field[disabled],
input::-webkit-datetime-edit-minute-field[disabled],
input::-webkit-datetime-edit-second-field[disabled] {
    color: GrayText;
}

/* If you update padding, border, or margin in the following ruleset, update
   DateTimeEditElement::customStyelForRenderer too. */
input::-webkit-datetime-edit-text {
    -webkit-user-modify: read-only !important;
    display: inline;
    font: inherit !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="datetime"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="week"]::-webkit-inner-spin-button {
    display: inline-block;
    position: static;
    -webkit-margin-start: 2px;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button;
    display: block;
    position: relative;
    cursor: default;
    /* This height property is ignored for input type "number" and others which
     * use RenderTextControlSingleLine as renderer which sets height of spin
     * button in layout(). */
    height: 1.5em;
    vertical-align: top;
    -webkit-flex: none;
    -webkit-user-select: none;
    -webkit-user-modify: read-only !important;
}
input::-webkit-calendar-picker-indicator {
    display: inline-block;
    width: 0.66em;
    height: 0.66em;
    padding: 0.17em 0.34em;
    -webkit-user-modify: read-only !important;
}

input::-webkit-calendar-picker-indicator:hover {
    background-color: #eee;
}

.winner-board p {
    text-align: center;
}

/* Gradient buttons */
.control-group {
    display: flex;
    @media(max-width: 30em) {
        justify-content: center;
        align-items: center;
    }
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
    font-weight: 400;
    color: #666;
    margin: 0;
    span {
        font-weight: 700;
        color: #333;
        text-shadow: 0 0 1px rgba(0,0,0,0.5);
    }
}

.landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    width: 100vw;
    position: relative;
    margin: 2em 0;

    &--cloud {
        background: none;
        margin: 2em 0 6em 0;
        border-radius: 0 0 0 150%;
        min-height: auto;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #2c3e50;
        z-index: -1;
        transform: skewY(6deg);
        transform-origin: top right;
        box-shadow: 0 0 .825em rgba(150, 150, 150, 0.8);
    }

    &--cloud::before {
        display: none;
    }


    .content {
        h1 {
            text-align: left;
        }

        background: white;
        max-width: 50vw;
        padding: 1.25em 2.5em;
        min-height: 20vh;
        box-shadow: 0 0 .825em rgba(150, 150, 150, 0.8);
    }

    &--cloud .content {
        background: none;
        min-height: none;
        box-shadow: none;
        padding: 0;

        h1 {
            text-align: center;
        }
    }

    &:nth-child(odd) {
        &::before {
            transform: skewY(-6deg);
            background: rgb(26, 146, 145);
        }
    }

    &--cloud:nth-child(odd)::before {
        transform: none;
        background: none;
    }

    @media(min-width: 60em) {

        .content {
            max-width: 30em;
            transform: translateX(10em) translateY(-10.5em);
        }

        &--cloud .content {
            transform: translateY(-5em);
        }

        &:nth-child(odd) {
            .content {
                transform: translateX(-10em) translateY(-10.5em);
            }
        }

        &--cloud:nth-child(even) .content {
            transform: translateY(-5em);
        }
    }
}

.footer {
    padding: 2em;
}

.table-wrapper {
    overflow: auto;
    max-width: 100%;
    background:
        linear-gradient(to right, white 30%, rgba(255,255,255,0)),
        linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%,
        radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;
}

table {
    border-collapse: collapse;
    border-spacing: .625em;
}

tr {
    border-bottom: 1px solid #ccc;
}

th {
    background-color: #555;
    color: #fff;
    white-space: nowrap;
}

th,
td {

    &:nth-child(2) {
        width: 100%;
    }
    text-align: left;
    padding: 0.5em 1em;
}

tr:nth-child(odd) {
    background-color: #eee;
}

.numeric {
    text-align: right;
}

.are-you-new {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .625em;
    }

    input {
        border-radius: .25em 0 0 .25em;
        border: .0625em solid #ccc;
        transform: translateX(.125em);
        display: inline-block;
        margin: 0;
        font-size: 1.25em;
        padding: .1875em;
    }

}

.activity-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activities-actions {
    position: fixed;
    bottom: 2em;

    button {
        @extend .btn-emoji;
    }

    .activity-board::after {
        @extend .btn-emoji--medal;
    }
    .activity-add::after {
        @extend .btn-emoji--add;
    }
    .activity-logs::after {
        @extend .btn-emoji--logs;
    }
    .activity-settings::after {
        @extend .btn-emoji--settings;
    }
}

.settings-list {
    list-style: none;
    padding: 0;

    li {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .controls {
        float: right;
    }
}

.settings__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        margin: 2em;
    }
}

.score-board {
    display: flex;
    align-items: baseline;
    justify-content: center;
    min-width: 20em;

    > div {

        min-width: 4em;
        margin: 0.5em;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-top: auto;

        @media(min-width: 40em) {
            min-width: 8em;
            margin: 2em;
            margin-top: auto;
        }
    }

    @keyframes score-animated {
        0% {
            padding-top: 0px;
        }
    }

    .score {
        color: #fff;
        animation: score-animated ease-in-out 1.8s;
        border-radius: 4px 4px 0 0;
        position: relative;
        height: 50px;
        margin-bottom: .625em;
    }

    .score__label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .name {
        min-height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }

    60% {
        opacity: 1;
        transform: translateY(20%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}


@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    60% {
        opacity: 0;
        transform: translateY(120%)
    }

    100% {
        opacity: 0;
        transform: translateY(100%)
    }
}

.scoreboard__title {
    color: #666;
    margin-bottom: .625em;
}

.scoreboard__view {
    span {
        color: #333;
        text-shadow: 0 0 1px rgba(0,0,0,0.5);
        display: none;
        &.active {
            display: inline-block;
            animation: slide-in .6s;
        }

        &.inactive {
            animation: slide-out .6s;
        }
    }
}

button {
    border-radius: 4px;
}

.activity__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    select {
        margin-bottom: 1.25em;
    }

    input {
        width: 100%;
        min-width: 92%;
        text-align: center;
        background: #fff;
        padding: .5em 0;
        border-radius: 5px;
        font-size: 1em;
    }

    button {
        line-height: 1.4em;
        font-size: .825em;
        margin: .625em;
    }

    h2 {
        font-size: 1.25em;
        margin-bottom: .625em;
    }
}

.edit {
    &--user {
        input {
            display: block;
            margin: 1.625em auto;
            font-size: 1em;
            text-align: center;
        }
    }
}

.emoji-board {
    max-width: 18em;
    height: 20em;
    overflow-y: scroll;
    text-align: justify;
    padding: .625em;
    border: 2px solid #ddd;
    border-radius: 6px;

    button {
        font-size: 1.25em;
        margin: auto;
        border: 0;
        display: inline-block;
    }
}

.btn {
    text-decoration: none;
    font-size: 1rem;
    color: white;
    padding: .625em 1.875em;
    display: inline-block;
    position: relative;
    border: 1px solid rgba(0,0,0,0.21);
    border-bottom: 4px solid rgba(0,0,0,0.21);
    border-radius: 4px;
    text-shadow: 0 1px 0 rgba(0,0,0,0.15);

    &--small {
        padding: .25em .625em;
    }
}
/* Gradient - ugly css is ugly */
.btn.cyan {
    background: rgba(27,188,194,1);
    background: linear-gradient(rgba(27,188,194,1) 0%, rgba(24,163,168,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1bbcc2', endColorstr='#18a3a8', GradientType=0);
}

.btn.red{
    background: rgba(250,90,90,1);
    background: linear-gradient(rgba(250,90,90,1) 0%, rgba(232,81,81,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa5a5a', endColorstr='#e85151', GradientType=0 );
}
.btn.orange {
    background: rgba(255,105,30,1);
    background: linear-gradient(rgba(255,105,30,1) 0%, rgba(230,95,28,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa5a5a', endColorstr='#e85151', GradientType=0 );
}
.btn.blue {
    background: rgba(102,152,203,1);
    background: linear-gradient(to bottom, rgba(102,152,203,1) 0%, rgba(92,138,184,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6698cb', endColorstr='#5c8ab8', GradientType=0 );
}
.btn.purple {
    background: rgba(203,153,197,1);
    background: linear-gradient(to bottom, rgba(203,153,197,1) 0%, rgba(181,134,176,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb99c5', endColorstr='#b586b0', GradientType=0 );
}
.btn.yellow {
    background: rgba(240,210,100,1);
    background: linear-gradient(rgba(240,210,100,1) 0%, rgba(229,201,96,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0d264', endColorstr='#e5c960', GradientType=0 );
}
.btn.green {
    background: rgba(130,200,160,1);
    background: linear-gradient(to bottom, rgba(130,200,160,1) 0%, rgba(124, 185, 149, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82c8a0', endColorstr='#82c79e', GradientType=0 );
}

.btn.red:active    {background: #E35252;}
.btn.orange:active {background: #E8601B;}
.btn.cyan:active   {background: #169499;}
.btn.blue:active   {background: #608FBF;}
.btn.purple:active {background: #BD8EB7;}
.btn.yellow:active {background: #DBC05B;}
.btn.green:active  {background: #72B08E;}


.btn-emoji {
    margin: 0 .625em;
    border-radius: 50%;
    font-size: 0.8em;
    width: 2.5em;
    height: 2.5em;
    text-indent: -9999px;
    position: relative;
    border: 2px solid #ccc;
    background: #fff;

    &::after {
        text-indent: 0;
        display: inline-block;
        position: absolute;
        height: 2.5em;
        width: 2.5em;
        line-height: 2.5em;
        text-align: center;
        outline: none;
        left: -.125em;
        top: -.125em;

        @media(min-width: 40em) {
            left: 0;
            top: 0;
        }
    }

    &--delete::after {
        content: '🗑';
    }
    &--edit::after {
        content: '✏️';
    }
    &--medal::after {
        content: '🏅';
    }
    &--add::after {
        content: '➕';
    }
    &--logs::after {
        content: '📄';
    }
    &--settings::after {
        content: '🎛';
    }
}
